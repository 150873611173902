import React, { Component } from 'react';
// import { navigate } from 'gatsby-link'
import Container from "../components/container"
import Copyright from "../components/copyright"
import Footer from "../components/footer"
import Header from "../components/header"
import Navbar from "../components/navbar"

import * as contactStyles from "./contact.module.css"
import * as halfBlockStyles from "../../src/components/halfblock.module.css"

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            number: "",
            company: "",
            message:"",
        };
    }

    handleSubmit = e => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state
            })
        })
        .then(() => {
            // navigate(form.getAttribute('action'))
            document.getElementById('contact-form').style.display = 'none';
            document.getElementById('success-message').style.display = 'inline';
        })
        .catch(error => alert(error));
    };

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    render() {
        const { name, email, number, company, message } = this.state;
        return (
            <React.Fragment>
            <Navbar></Navbar>
            <Container>
                <Header headerText="Entre em contato" />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md">
                            <h4 className="mt-5 mb-4" style={{letterSpacing: '4px'}}>ESCRITÓRIO</h4>
                            <p>Av. Dr. Mendel Steinbruch, km 7, s/n</p>
                            <p>Maracanaú-CE</p>
                            <p>+55 (85) 3401-2014</p>
                            <p><a href="mailto:contato@pemalex.com.br">contato@pemalex.com.br</a></p>
                        </div>
                        <div id="success-message" className="col-md my-5" style={{display: 'none'}}>
                            <h3>Sua mensagem foi enviada com sucesso! Entraremos em contato em breve.</h3>
                        </div>
                        <div id="contact-form" data-sal="slide-left" data-sal-delay="400" data-sal-easing="ease-out-circ" data-sal-duration="1900" className="col-md my-5">
                            <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" className={contactStyles.label} onSubmit={this.handleSubmit}>
                                <input type="hidden" name="contact" value="contact" />
                                <div className="form-group">
                                    <label htmlFor="first-name">Nome *</label>
                                    <input type="text" className="form-control rounded-0" name="name" value={name} onChange={this.handleChange} required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control rounded-0" id="exampleInputEmail1" aria-describedby="email" name="email" value={email} onChange={this.handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone-number">Telefone *</label>
                                    <input type="number" className="form-control rounded-0" name="number" value={number} onChange={this.handleChange} required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="company" className="mt-2">Empresa</label>
                                    <input type="text" className="form-control rounded-0" name="company" value={company} onChange={this.handleChange}/>
                                </div>
                                <div>
                                    <label htmlFor="message">Mensagem *</label>
                                    <textarea name="message"  className="form-control rounded-0" id="exampleFormControlTextarea1" rows="3" value={message} onChange={this.handleChange}required/>
                                </div>
                                <p className="mt-2">*Informações obrigatórias</p>
                                <button type="submit" className={halfBlockStyles.button}>Enviar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </Container>
            <div className={contactStyles.parallax}></div>
            <Footer />
            <Copyright />
            </React.Fragment>
        )
    }
}

export default Contact;